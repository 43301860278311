<template>
  <div class="d-flex justify-center align-center mb-4 simple-date-header">
    <router-link v-if="goTo" :to="goTo" tag="button" style="position: absolute; left: 0" class="ml-2">
      <v-icon color="main">mdi-arrow-left</v-icon>
    </router-link>

    <slot name="left-side-options"></slot>

    <h3 class="main--text">{{ date }}</h3>

    <slot name="right-side-options"></slot>
  </div>
</template>

<script>
export default {
  name: "SimpleDateHeader",
  props: {
    date: {
      type: String,
      required: true
    },
    goTo: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
};
</script>

<style scoped>
.simple-date-header {
  width: 100%;
  height: 52px;
  background-color: #fff;
  box-shadow: 0 4px 10px -6px rgba(0, 0, 0, 0.2);
  position: relative;
}
</style>
